.newsBox {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.newsContent.hasImage {
    margin-left: 40%;
}

.newsImg {
    position: absolute;
    object-fit: cover;
    width: 38%;
    margin-right: 15px;
    left: 0;
    bottom: 0;
    top: 0;
    height: 100%;
}

.newsTitle {
    color: #000;
    font-size: 18px;
    font-weight: 700;
}

.newsDesc {
    color: #3b3b3b;
    font-size: 15px;
}

.newsDate {
    display: flex;
    font-size: 14px;
    margin-bottom: 8px;
    color: #3b3b3b;
}
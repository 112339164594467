#region {
    margin: 25px 0;
}

.boxSlider .infoBox {
    margin: 5px 0;
}

.slick-dots {
    position: absolute;
    text-align: left;
}

.slick-dots li {
    margin: 0 10px;
    width: auto;
    height: auto;
}

.slick-dots li:first-child {
    margin: 0 10px 0 0;
}

.slick-dots li button {
    width: 35px;
    height: 10px;
    opacity: .25;
    background-color: #000;
}

.slick-dots li.slick-active button {
    opacity: 1;
    background-color: rgb(255, 0, 8);
}

.slick-dots li button:before {
    font-size: 0;
}

@media screen and (max-width: 768px) {
    .sliderRight{
        margin-bottom: 80px;
    }
}
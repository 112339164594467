@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;700&display=swap');

h1, h2, h3, h4, h5, h6, p, a, button, input, textarea, a, li {
    font-family: 'IBM Plex Sans', sans-serif;
}

h4 {
    text-transform: uppercase;
}

input, button, textarea {
    outline: none;
}

.container {
    position: relative;
}

.content {
    max-width: 800px;
    margin: 0 auto;
}


a {
    transition: .2s;
}

a:hover {
    text-decoration: none;
    opacity: 0.8;
}

@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1500px;
    }
}

@media(min-width: 576px){
    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
        padding-right: 25px;
        padding-left: 25px;
    }
}

.rowSlicePosts {
    display: flex;
    flex-direction: row;
    margin-left: -15px;
    margin-right: -15px;
}

.rowSlicePosts > div {
    padding-right: 15px;
    padding-left: 15px;
}

.rowSlicePosts .colPosts {
    width: 30%;
}

.rowSlicePosts .colSlider {
    width: 30%;
}

.rowSlicePosts .colThumbs {
    width: 40%;
}

@media(max-width: 991px){
    .rowSlicePosts {
        flex-direction: column;
    }

    .rowSlicePosts .colPosts {
        width: 100%;
    }
    
    .rowSlicePosts .colSlider {
        width: 100%;
        margin-bottom: 60px;
    }
    
    .rowSlicePosts .colThumbs {
        width: 100%;
    }
}

@media(max-width: 767px){
    .rowSlicePosts .colSlider {
        margin-bottom: 0;
    }
}

.slick-slide img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    height: 415px;
}

.infoBox {
    width: 100%;
}

.infoBox ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}

.infoBox ul li:not(:last-child) {
    margin-right: 15px;
}

.infoBox ul li a, .infoBox ul li {
    font-weight: 700;
    color: #3b3b3b;
    font-size: 13px;
}

.infoBox.white ul li a, .infoBox.white ul li {
    color: #fff;
    opacity: 0.8;
}

.title {
    text-align: left;
    margin-bottom: 25px;
    font-weight: 700;
    text-transform: uppercase;
    color: #3b3b3b;
    font-size: 24px;
    transition: .2s;
}

.titleMoreNews {
    text-transform: uppercase;
    color: #3b3b3b;
    font-size: 18px;
    font-weight: 700;
    margin: 40px 0 15px;
}

.newsSingleTitle{
    font-weight: 700;
    font-size: 32px;
    text-align: center;
}

.newsSingleSubtitle{
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 25px;
    color: #3b3b3b;
    text-align: center;
}


.titleCategories {
    list-style: none;
    margin: 0 0 25px;
    padding: 0;
    display: flex;
    float: left;
}

.titleCategories li {
    text-transform: uppercase;
    color: #3b3b3b;
    font-size: 18px;
    transition: .2s;
}

.titleCategories li:first-child {
    font-weight: 700;
    margin-right: 8px;
}

.titleCategories li.active {
    font-weight: 700;
    color: rgb(255, 0, 8);
}

.titleCategories li:hover {
    opacity: 0.8;
}

.titleCategories li:not(:last-child) {
    margin-right: 25px;
}

.titleCategories li:not(:first-child) {
    cursor: pointer;
}

.css-2b097c-container {
    float: left;
    width: 235px;
    margin-top: -6px;
    margin-bottom: 20px;
}

@media(max-width: 575px){
    .css-2b097c-container {
        width: 100%;
    }
}


.css-1pahdxg-control, .css-1pahdxg-control:hover, .css-1pahdxg-control:focus, .css-yk16xz-control:focus {
    border-color: #df0700 !important;
    box-shadow: 0 0 0 1px #df0700;
}

.infinite-scroll-component  {
    overflow: hidden !important;
}

.show-all {
    white-space: nowrap;
    color: #f00;
    font-size: 14px;
    padding-top: 5px;
    margin-left: 10px;
    text-transform: uppercase;
}

.oldNews {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 25px 0;
}

.oldNews a {
    display: flex;
    border: solid 2px #3b3b3b;
    padding: 15px 30px;
    border-radius: 30px;
    text-transform: uppercase;
    font-weight: 700;
    color: #3b3b3b;
}

@media(max-width: 575px){
    .show-all {
        position: absolute;
        right: 15px;
        top: 0;
    }
}
.sliderGallery {
    padding: 0 7px;
    margin: 0 -7px;
    margin-bottom: 25px;
}

.imgGallery {
    position: relative;
}

.imgGallery img {
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.typePic {
    position: absolute;
    bottom: 15px;
    left: 15px;
    background: #fff;
    font-size: 11px;
    font-weight: 700;
    width: 60px;
    text-align: center;
    border-radius: 10px;
}

.contentGallery {
    font-size: 14px;
    border: 1px solid #ccc;
    border-top: none;
    padding: 8px 15px 0;
    border-radius: 0 0 2px 2px;
}

.contentGallery b {
    color: #3b3b3b;
    font-weight: 700;
}

.boxFlex {
    display: flex;
    justify-content: space-between;
}

.boxFlex .item a svg {
    color: #ed1b24;
    font-size: 19px;
    margin-top: 22px;
}

@media screen and (max-width: 768px) {
    .sliderGallery {
        margin: auto;
    }
    .slick-prev, .slick-next {
        display: none !important;
    }
}
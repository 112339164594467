#top {
    background-color: rgb(255, 0, 8);
    padding: 18px 0;
    height: 36px;
    display: flex;
    align-items: center;
}

#top h1 {
    line-height: 0;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: #FFF;
}

#langBox {
    position: absolute;
    top: -18px;
    right: 25px;
    display: flex;
    flex-direction: row;
}

.langButton {
    height: 36px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: #FFF;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    opacity: .8;
    padding-right: 0;
}

.langButton:hover {
    opacity: 1;
}

.mainSubmenuTitle a:hover {
    opacity: 1;
    cursor: default;
}

.langButton.active {
    opacity: 1;
    font-weight: 700;
}

@media(max-width: 575px){
    #langBox {
        right: 15px;
    }
}

@media(max-width: 767px){
    #top h1 {
        text-align: left;
    }
}

header {
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    margin-bottom: 15px;
}

header.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 999;
    box-shadow: 0px 7px 20px 0px rgb(0 0 0 / 19%);
}

@media(max-width: 991px){
    header.fixed #searchBox {
        display: none;
    }
}    

#logo {
    height: 50px;
}

#searchBox {
    width: 300px;
    height: 46px;
    position: relative;
}

#searchBox input {
    border: solid 2px #e5e5e5;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 23px;
    padding-left: 20px;
    padding-right: 40px;
}

#searchBox svg {
    top: 10px;
    right: 14px;
    position: absolute;
    color: #e5e5e5;
    font-size: 26px;   
}

#mainMenu {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
}

#mainMenu > li {
    min-height: 80px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#mainMenu > li:not(:last-child) {
    margin-right: 28px;
}

#mainMenu > li a {
    min-height: 80px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #3b3b3b;
    display: flex;
    height: 100%;
    align-items: center;
}

#headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#mainSubmenu.hide {
    display: none;
}

#mainSubmenu.show {
    display: flex;
}

#mainSubmenu {
    flex-direction: column;
}

#mainSubmenu ul {
    list-style: none;
    padding: 0;
}

#mainSubmenu ul li {
    white-space: nowrap;
}

#mainSubmenu ul li a {
    text-transform: none;
}

#mainSubmenu ul li:first-child {
    font-weight: 700;
}

#mainSubmenu ul li:not(:first-child) a {
    font-weight: 400;
}

@media(min-width: 992px){
    .mainSubmenuMore {
        
        position: absolute;
        bottom: 15px;
        right: 0;
    }

    .mainSubmenuMore a:hover {
        cursor: pointer;
    }

    #mainMenu > li:hover > #mainSubmenu {
        display: flex;
    }

    #mainSubmenu {
        flex-direction: row;
        background: #fff;
        position: absolute;
        right: 0;
        top: 52px;
        z-index: 999;
        padding: 20px 0;
        border-top: solid 5px #f00;
        box-shadow: 0px 19px 20px 0px rgb(0 0 0 / 12%);
    }

    #mainSubmenu {
        top: 77px;
    }

    .fixed #mainSubmenu {
        top: 75px;
    }

    #mainSubmenu ul {
        margin: 0 30px;
        padding: 0;
    }

    #mainSubmenu ul li {
        white-space: nowrap;
        margin: 8px 0;
    }

    #mainSubmenu > ul > li a {
        min-height: auto;
    }
}

@media(max-width: 991px){
    header {
        padding: 15px 0;
    }

    #mainMenu {
        padding: 20px 0 0;
        background: #fff;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 170px;
        z-index: 99;
        padding-left: 15px;
        overflow-y: auto;
    }

    header.fixed #mainMenu {
        top: 80px;
    }

    #mainMenu.show {
        display: block;
    }

    #mainMenu.hide {
        display: none;
    }

    #mainMenu li a {
        width: 100%;
        border-bottom: solid 1px #cbcccd;
    }
    
    #mainMenu li {
        margin-right: 28px;
    }

    #headerBox {
        justify-content: center;
        flex-direction: column;
    }

    #searchBox {
        width: 100%;
        margin-top: 15px;
    }

    #mainSubmenu {
        width: 100%;
        padding-left: 25px;
    }
}

button.openMenu {
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    top: 10px;
    right: 15px;
    display: none;
}

.rowToggle {
    width: 26px;
    height: 3px;
    margin: 5px 0;
    background: #f00;
    border-radius: 2px;
}

@media screen and (max-width: 991px) {
    button.openMenu {
        display: block;
    }
}
#institucional {
    margin: 25px 0;
}

#mainBannerInstitucional {
    display: flex;
    height: 100%;
    width: 100%;
}

#mainBannerInstitucional h2 {
    font-weight: 700;
    color: #fff;
}

#mainBannerInstitucional img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    z-index: -2;
    transition: .5s;
}

.mbOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(
    0deg
    , rgb(0 0 0 / 97%) 0%, rgba(0,0,0,0) 45%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

#mbrBottomLeft .mbOverlay {
    background: linear-gradient(
    0deg
    , rgb(0 0 0 / 97%) 0%, rgba(0,0,0,0) 70%);
}

#mbLeft, #mbrTop {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    border: solid 2px #fff;
    padding: 15px;
    display: flex;
    flex-direction: column-reverse;
}

#mbLeft:hover img, #mbrTop:hover img, #mbrBottomLeft:hover img, #mbrBottomRight:hover img {
    width: 105%;
    height: 105%;
}

#mbLeft {
    min-height: 380px;
    width: 45%;
    display: flex;
    align-items: flex-start;
}
#mbLeft h2 {
    font-size: 21px;
}
#mbRight {
    display: flex;
    flex-direction: column;
    width: 55%;
}
#mbrTop {
    height: 60%;
    display: flex;
    align-items: flex-start;
}
#mbrTop h2 {
    font-size: 21px;
}
#mbrBottom {
    display: flex;
    height: 40%;
}
#mbrBottomLeft {
    display: flex;
    width: 50%;
    align-items: flex-start;
}
#mbrBottomLeft h2 {
    font-size: 14px;
}
#mbrBottomRight {
    display: flex;
    width: 50%;
    align-items: flex-start;
}
#mbrBottomRight h2 {
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    #mainBannerInstitucional {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 991px) {
    .lastBannerInstitucional {
        padding-bottom: 25px;
    }
}


@media screen and (max-width: 575px) {
    #mainBannerInstitucional {
        height: auto;
        margin-top: 15px;
    }
}
#brand {
    margin: 25px 0;
}

#mainBannerBrand {
    height: calc(100% - 20px);
}

#mainBannerBrand #mbLeft {
    height: 100%;
}

#mainBannerBrand h2 {
    font-weight: 700;
    color: #fff;
}

#mainBannerBrand img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    z-index: -2;
    transition: .5s;
}
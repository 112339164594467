#mainBanner {
    display: flex;
    height: calc(100vh - 155px);
    max-height: 1000px;
    width: 100%;
}

#mainBanner h2 {
    margin-bottom: 0;
}

#mainBanner h2 {
    font-weight: 700;
    color: #fff;
}

#mainBanner img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    z-index: -2;
    transition: .5s;
}

.mbOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(
    0deg
    , rgb(0 0 0 / 97%) 0%, rgba(0,0,0,0) 45%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

#mbrBottomLeft .mbOverlay,
#mbrBottomRight .mbOverlay {
    background: linear-gradient(
    0deg
    , rgb(0 0 0 / 97%) 0%, rgba(0,0,0,0) 70%);
}

#mbLeft, #mbrTop, #mbrBottomLeft, #mbrBottomRight {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    border: solid 2px #fff;
    padding: 15px;
    display: flex;
    flex-direction: column-reverse;
}

#mbLeft:hover img, #mbrTop:hover img, #mbrBottomLeft:hover img, #mbrBottomRight:hover img {
    width: 105%;
    height: 105%;
}

#mbLeft {
    width: 45%;
    display: flex;
    align-items: flex-start;
}
#mbLeft h2 {
    font-size: 21px;
}
#mbRight {
    display: flex;
    flex-direction: column;
    width: 55%;
}
#mbrTop {
    height: 60%;
    display: flex;
    align-items: flex-start;
}
#mbrTop h2 {
    font-size: 21px;
}
#mbrBottom {
    display: flex;
    height: 40%;
}
#mbrBottomLeft {
    display: flex;
    width: 50%;
    align-items: flex-start;
}
#mbrBottomLeft h2 {
    font-size: 18px;
}
#mbrBottomRight {
    display: flex;
    width: 50%;
    align-items: flex-start;
}
#mbrBottomRight h2 {
    font-size: 18px;
}

@media(max-width: 768px) {
    #mainBanner {
        margin-bottom: 25px;
    }
}


@media(max-width: 575px) {
    #mainBanner {
        flex-direction: column;
        height: auto;
        max-height: none;
    }

    #mbLeft {
        width: 100% !important;
        min-height: 350px;
    }

    #mbRight {
        width: 100% !important;
    }

    #mbrTop {
        width: 100% !important;
        min-height: 350px;
    }

    #mbrBottomLeft {
        width: 100% !important;
        min-height: 250px;
    }

    #mbrBottom {
        width: 100% !important;
        display: flex;
        height: auto;
        flex-direction: column;
    }

    #mbrBottomRight {
        width: 100% !important;
        min-height: 250px;
    }
    
}